import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Tooltip,
  Autocomplete,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Stack,
  Shadows,
  Alert,
  Button,
  Paper,
  Snackbar,
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { pdfjs } from 'react-pdf';
import { GlobalWorkerOptions } from 'pdfjs-dist';

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

import { db } from "../config/firebase.js";

import { Document, Page } from "react-pdf";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { collection, doc, where, query, updateDoc, deleteDoc, getDoc, getDocs, addDoc } from "firebase/firestore"

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styled from "@emotion/styled";
import ProCorpCX from "./ProCorpCX.js";
import enzo from "./enzo.jpg";
import { opCategories, ratingOpinions } from "./MyData.js";
import { CriteriaDisplay } from "./Ratings.js";

import ProEducation from "./ProEducation.js";
import ProCorpPrivate from "./ProCorpPrivate.js";
// import YourEvaluations from "./YourEvaluations";
import PollInvites from "./PollInvites.js";
import NewPollsandRatings from "./NewPollsandRatings.js";
import { RowingOutlined, SignalWifiStatusbarNullRounded } from "@mui/icons-material";
import OpinionUpload from "./OpinionUpload.js";

const button1Color = "#5B76D7";
const button2Color = "#f5f5f5";
const buttonSelectedColor = "#af52bf";

const useStyles = styled((theme) => ({
  selected: { backgroundColor: buttonSelectedColor, color: "white" },
  notSelected: { backgroundColor: button2Color, color: "grey" },
}));

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.10.377/es5/build/pdf.worker.min.js';

function OpinionsOptions({ userId, onLogin, onLogout }) {
  const [companyIdError, setCompanyIdError] = useState(false)
  // const [showAlert, setShowAlert] = useState(true);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showLoginAlertPrompt, setShowLoginAlertPrompt] = useState(false)
  const [ratings, setRatings] = React.useState({
    userId: null,
    C1: 3,
    C2: 2,
    C3: 5,
    C4: 1,
    C5: 3,
  });
  const [showRatingsForm, setShowRatingsForm] = useState(false);
  const [showEnterCompanyId, setShowEnterCompanyId] = useState(false);
  const [correctCompanyIdEntered, setCorrectCompanyIdEntered] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // display States = "nullOpList", "showOpList", "showOp", "showAndRateOp"
  const [displayState, setDisplayState] = useState("showOpList")


  const [selectedCategory, setSelectedCategory] = useState(opCategories[0]);
  const [selectedButton, setSelectedButton] = useState(1);
  const [arrayOfOpinions, setArrayOfOpinions] = useState([]);
  // const [showOpinionFlag, setShowOpinionFlag] = useState(false)
  // const [showArrayOfOpinions, setShowArrayOfOpinions] = useState(true)
  const [selectedOpinionInfo, setSelectedOpinionInfo] = useState('');
  const [selectedOpinion, setSelectedOpinion] = useState('');
  const [dbDataUpdated, setDbDataUpdated] = useState(false);

  // const [selectedRow, setSelectedRow] = useState(null);

  // const buttonClassFormat = useStyles();
  const [activeOption, setActiveOption] = useState("pro");

  const [selectedState, setSelectedState] = React.useState(null);
  const [uId, setUId] = useState(userId);

  const [sort, setSort] = useState({ column: "count", order: "desc" });
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour clock with AM/PM notation
  };
  // const handlePrivateClicked = () => {
  //   setShowEnterCompanyId(!showEnterCompanyId)

  //   // setShowLogin(false);
  //   setCompanyIdError(false);
  //   setSelectedButton(!selectedButton);
  //   setSelectedButton(selectedButton === 1 ? null : 1);
  // };

  const handleSort = (column) => {
    if (sort.column === column) {
      setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
    } else {
      setSort({ column: column, order: "asc" });
    }
  };

  function checkHasVoted(entry) {

    let uidArray;

    if (userId) {
      uidArray = entry.hasVoted.some((obj) => obj === userId)
    }
    // if (!uidArray) {
    //   setDisplayState('showOp')
    // getOpinion(entry.opinion)
    setSelectedOpinionInfo(entry)
    setSelectedOpinion(entry.opinion);
    // setDisplayState('showOp')

    return uidArray;
  }

  const getOpinion = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      if (blob.type === "text/html") {
        const reader = new FileReader();

        reader.onload = function (event) {
          const htmlContent = event.target.result;
          setSelectedOpinion(htmlContent);
        };
        reader.readAsText(blob);
      } else {
        setSelectedOpinion("Not an HTML Blob");
      }
      // setSelectedOpinion(blob);
    } catch (error) {
      setSelectedOpinion('Failed to load content');
      console.error(error);
    }
  }

  const fetchData = async () => {
    try {
      const activeTableQuery = query(collection(db, 'opinionsTable'))
      const activeTableSnapshot = await getDocs(activeTableQuery);
      const activeTableData = activeTableSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const filteredArray = activeTableData.filter(obj => obj.selectedCategory === selectedCategory);
      setArrayOfOpinions(filteredArray);
      if (filteredArray.length === 0)
        setDisplayState("nullOpList")
    } catch (error) {
      console.log("ERROR!")
      console.error("Error fetching Opinions data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userId, displayState, dbDataUpdated, isDisabled, showLoginAlertPrompt, selectedOpinionInfo, selectedCategory, ratings, showRatingsForm, selectedButton]);

  async function addOrUpdateDocument(collectionName, data, rowId) {
    try {
      let q, docRef, querySnapshot;
      if (rowId) {
        docRef = doc(db, collectionName, rowId);
        querySnapshot = await getDoc(docRef);
      } else {
        q = query(
          collection(db, collectionName),
          where("title", "==", data.title)
        )
        querySnapshot = await getDocs(q);
        docRef = querySnapshot.docs[0].ref;
      }

      if (!querySnapshot.empty) {
        await updateDoc(docRef, data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      } else {
        await addDoc(collection(db, collectionName), data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      }
    } catch (error) {
      console.error("Error adding or updating document:", error);
    }
  }

  const handleGoBack = () => {
    setDisplayState("showOpList")
  }
  const handleSubmitRating = (event) => {
    // event.preventDefault();

    const rowToUpdate = arrayOfOpinions.find(
      (row) => row.opinion === selectedOpinionInfo.opinion);
    const updateRow = { ...rowToUpdate };
    let votedUsers = updateRow.hasVoted
    if (!votedUsers.find((user) => (user === userId))) {
      votedUsers.push(userId)
    }

    // updateRow.CXCategoryOptions = CXCategoryOptions
    // updateRow.Company = selectedCompany;
    // updateRow.Business = selectedBusiness
    let currentRating = 0 || updateRow.rating;
    const updatedNumVotes = (0 || updateRow.numVotes) + 1

    updateRow.c1CumVoteCounts = (updateRow.c1CumVoteCounts || 0) + 1;
    updateRow.c1CumVotes = (updateRow.c1CumVotes || 0) + ratings.C1;
    updateRow.c1Rating = Math.ceil(
      updateRow.c1CumVotes / updateRow.c1CumVoteCounts
    );
    updateRow.c2CumVoteCounts = (updateRow.c2CumVoteCounts || 0) + 1;
    updateRow.c2CumVotes = (updateRow.c2CumVotes || 0) + ratings.C2;
    updateRow.c2Rating = Math.ceil(
      updateRow.c2CumVotes / updateRow.c2CumVoteCounts
    );
    updateRow.c3CumVoteCounts = (updateRow.c3CumVoteCounts || 0) + 1;
    updateRow.c3CumVotes = (updateRow.c3CumVotes || 0) + ratings.C3;
    updateRow.c3Rating = Math.ceil(
      updateRow.c3CumVotes / updateRow.c3CumVoteCounts
    );
    updateRow.c4CumVoteCounts = (updateRow.c4CumVoteCounts || 0) + 1;
    updateRow.c4CumVotes = (updateRow.c4CumVotes || 0) + ratings.C4;
    updateRow.c4Rating = Math.ceil(
      updateRow.c4CumVotes / updateRow.c4CumVoteCounts
    );
    updateRow.c5CumVoteCounts = (updateRow.c5CumVoteCounts || 0) + 1;
    updateRow.c5CumVotes = (updateRow.c5CumVotes || 0) + ratings.C5;
    updateRow.c5Rating = Math.ceil(
      updateRow.c5CumVotes / updateRow.c5CumVoteCounts
    );
    const r1 = updateRow.c1Rating;
    const r2 = updateRow.c2Rating
    const r3 = updateRow.c3Rating
    const r4 = updateRow.c4Rating
    const r5 = updateRow.c5Rating

    const totalRatings = r1 + r2 + r3 + r4 + r5;
    const opRat = totalRatings / ratingOpinions.length

    // const updatedRating = parseFloat(((currentRating + opRat) / 2).toFixed(2));

    console.log(r1, r2, r2, r3, r5, opRat, currentRating, ratingOpinions.length, opRat)

    const opinionsData = {
      UserId: userId,
      title: updateRow.title,
      rating: opRat,
      selectedCategory: updateRow.selectedCategory,
      opinion: updateRow.opinion,
      submissionDate: new Date(),
      numVotes: updatedNumVotes,
      hasVoted: votedUsers
    };

    addOrUpdateDocument("opinionsTable", opinionsData, updateRow.id);
    // setSelectedOpinionInfo("");
    setDbDataUpdated(!dbDataUpdated); // toggle;

    // updateCXRating(selectedCompany, updateRow.CXRating);
    // resetSort(event);
    // fetchData();
    // setShowForm(false);

    setIsDisabled(false);
    setDisplayState('showOp');
    // setShowOpinionFlag(true)
    // setShowRatingsForm(false)
    setShowLoginAlertPrompt(false);
    setShowLoginAlert(false);
  };

  const handleShowOpinion = (entry, index) => {
    // setSelectedOpinionInfo(entry)
    //setDisplayState('showOp');
    // setShowOpinionFlag(true)

    if (userId) {
      if (!checkHasVoted(entry)) {
        setDisplayState('showAndRateOp')
        setShowLoginAlertPrompt(false)
      } else setDisplayState('showOp')
    }
    else {
      setDisplayState('showOp')
      setIsDisabled(false); //check the need for this...  
      setShowLoginAlertPrompt(true)
    }
    setSelectedOpinionInfo(entry)
    // getOpinion(entry.opinion)
    setSelectedOpinion(entry.opinion);
  }
  const handleRateOpinion = (entry, index) => {

    // setIsDisabled(true);
  }

  const handleButtonClick = (index) => {
    if (selectedButton === index) {
      setSelectedButton(null);
      //  if (displayState != 'nullOpList')
      setDisplayState("showOpList")
      // setSelectedCategory('')
    } else {
      // if (displayState != 'nullOpList')
      setDisplayState("showOpList")
      setSelectedButton(index);
      setSelectedCategory(opCategories[index - 1]) //array starts with [0]
    }
    // console.log("displayState:", displayState, selectedCategory)
    if (!userId) setShowLoginAlertPrompt(true)
  };

  const handleClose = () => {
    setShowLoginAlert(false);
    setShowLoginAlertPrompt(false)
    setIsDisabled(false)
  };

  const handleRatingChange = (criteria) => (event, ratingValue) => {
    setRatings((prevRatings) => ({ ...prevRatings, [criteria]: ratingValue }));
  };

  function getRatings(entry, index) {
    let [c1, c2, c3, c4, c5] = ratingOpinions;

    return (
      <Card
        elevation={10}
        style={{
          align: "left",
          justifyContent: "flex-start",
          marginTop: "1em",
          marginBottom: "1em",
          marginLeft: "10px",
          width: "250px",
        }}
      >
        < div >
          <Stack marginTop="10px" marginLeft='10px' direction="column">
            {c1 && (
              <CriteriaDisplay
                criteria={c1}
                value={ratings.C1}
                onChange={handleRatingChange("C1")}
              />
            )}
            {c2 && (
              <CriteriaDisplay
                criteria={c2}
                value={ratings.C2}
                onChange={handleRatingChange("C2")}
              />
            )}
            {c3 && (
              <CriteriaDisplay
                criteria={c3}
                value={ratings.C3}
                onChange={handleRatingChange("C3")}
              />
            )}
            {c4 && (
              <CriteriaDisplay
                criteria={c4}
                value={ratings.C4}
                onChange={handleRatingChange("C4")}
              />
            )}
            {c5 && (
              <CriteriaDisplay
                criteria={c5}
                value={ratings.C5}
                onChange={handleRatingChange("C5")}
              />
            )}
          </Stack>

        </div >
        <Button
          variant="contained"
          backgroundcolor="#333"
          onClick={handleSubmitRating}
          style={{
            border: "none",
            borderRadius: 10,
            fontWeight: 'bold',
            marginTop: '10px',
            marginLeft: '15px',
            marginRight: '15px',
            marginBottom: '15px',
            color: "white",
            backgroundColor: "#5B76D7",
          }}>
          SUBMIT
        </Button>
      </ Card>
    );
  }
  function renderOpinion() {
    return (
      selectedOpinionInfo && (
        <>
          < Card
            elevation={10}
            style={{
              align: "left",
              justifyContent: "flex-start",
              marginTop: "1em",
              marginBottom: "1em",
              marginLeft: "6px",
              width: "1156px",
              whiteSpace: 'pre-line'
            }}>
            {/* {console.log("Sel op Info:", selectedOpinionInfo)} */}
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              backgroundcolor="#333"
              onClick={handleGoBack}
              style={{
                position: 'absolute',
                marginLeft: '380px',
                border: "none",
                borderRadius: 20,
                fontWeight: 'bold',
                marginTop: '26px',
                marginBottom: '15px',
                color: "white",
                backgroundColor: "#5B76D7",
              }}>
              GO BACK
            </Button>
            {((displayState === 'showOp') || (displayState === 'showAndRateOp')) && (
              <>
                <Typography paddingLeft='15px' paddingRight='15px' textAlign='justify' sx={{ paddingTop: '6px', color: 'blue', fontWeight: 'bold', whiteSpace: 'pre-wrap' }} >{selectedOpinionInfo.title}</Typography>
                <Typography paddingLeft='15px' paddingRight='15px' textAlign='justify' sx={{ color: 'blue', fontWeight: 'bold', whiteSpace: 'pre-wrap' }} >Author: {selectedOpinionInfo.userId}</Typography>
                <Typography paddingLeft='15px' paddingRight='15px' textAlign='justify' sx={{ color: 'blue', fontWeight: 'bold', whiteSpace: 'pre-wrap' }} >{selectedOpinionInfo.submissionDate.toDate().toLocaleString(
                  "en-US",
                  dateOptions
                )}</Typography><br />
                {/* <Box whiteSpace='pre-line'>
              <Document file={selectedOpinion}>
                <Page pageNumber={1} />
              </Document> */}
                <Typography paddingLeft='15px' paddingRight='15px' textAlign='justify' sx={{ whiteSpace: 'pre-wrap' }} >{selectedOpinion}</Typography>
                {/* </Box> */}
              </>
            )}
            {showLoginAlertPrompt && (
              <Snackbar
                open
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                  marginTop: "100px", minWidth: '150px'
                }}
              >
                < Alert severity="info" action={
                  < IconButton onClick={handleClose} >
                    <CloseIcon />
                  </IconButton >
                }>
                  <span style={{ fontStyle: 'italic' }}>Login to Rate if not done...</span>
                </Alert >
              </Snackbar>
            )}
          </Card >
        </>
      )
    )
  }

  function renderOpinionList() {
    return (
      <>
        {/* {console.log("opinionsArray:", arrayOfOpinions, "display State:", displayState, "cat:", selectedCategory)} */}
        {/* 
        {selectedCategory ? ( */}
        <Card
          elevation={10}
          style={{
            align: "left",
            justifyContent: "flex-start",
            marginTop: "1em",
            marginBottom: "1em",
            marginLeft: "6px",
            width: "1200px",
            overflowX: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          <Box>
            <Grid container spacing={1} direction='row'>
              <Grid item xs={12}  >
                <Card
                  elevation={10}
                  sx={{ width: "1190px" }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      overflow: "auto", marginTop: "0.5em", display: 'flex',
                      marginLeft: '7px', marginRight: '6px'
                    }}
                  >
                    <Table size="small" sx={{ width: '99%' }}>
                      <TableHead
                        sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                      >
                        <TableRow >
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              width: "50%",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            Title
                            {sort.order === "asc" ? (
                              <ArrowDropUp
                                sx={{ color: "white" }}
                                onClick={() => handleSort("Title")}
                              />
                            ) : (
                              <ArrowDropDown
                                sx={{ color: "white" }}
                                onClick={() => handleSort("Title")}
                              />
                            )}
                          </TableCell>

                          <TableCell
                            sx={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-all",
                              width: "10%",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            User ID
                            {sort.order === "asc" ? (
                              <ArrowDropUp
                                sx={{ color: "white" }}
                                onClick={() => handleSort("userId")}
                              />
                            ) : (
                              <ArrowDropDown
                                sx={{ color: "white" }}
                                onClick={() => handleSort("userId")}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              width: "5%",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            Rating (0-5)
                            {sort.order === "asc" ? (
                              <ArrowDropUp
                                sx={{ color: "white" }}
                                onClick={() => handleSort("submissionDate")}
                              />
                            ) : (
                              <ArrowDropDown
                                sx={{ color: "white" }}
                                onClick={() => handleSort("submissionDate")}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              width: "20%",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            Submission Date
                            {sort.order === "asc" ? (
                              <ArrowDropUp
                                sx={{ color: "white" }}
                                onClick={() => handleSort("submissionDate")}
                              />
                            ) : (
                              <ArrowDropDown
                                sx={{ color: "white" }}
                                onClick={() => handleSort("submissionDate")}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-all",
                              width: "15%",
                              fontWeight: "bold",
                              color: "white",
                              paddingRight: '0px'
                            }}
                          >

                            {/* {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("question")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("question")}
                            />
                          )} */}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {arrayOfOpinions.map((entry, index) => (
                          <>
                            {/* {!hasVoted(entry) && ( */}
                            <TableRow>
                              <TableCell sx={{ verticalAlign: 'top', marginTop: "1em", backgroundColor: "#F7F9F9" }} > {entry.title} </TableCell>
                              <TableCell sx={{ verticalAlign: 'top' }}>{entry.userId}</TableCell>
                              <TableCell sx={{ verticalAlign: 'top' }}>{entry.rating}</TableCell>
                              <TableCell sx={{ verticalAlign: 'top' }}>{entry.submissionDate.toDate().toLocaleString(
                                "en-US",
                                dateOptions
                              )}
                              </TableCell>
                              <TableCell sx={{ verticalAlign: 'top', marginTop: "1em", backgroundColor: "#F7F9F9" }}>
                                <Button
                                  variant="contained"
                                  backgroundcolor="#333"
                                  disabled={isDisabled}
                                  // endIcon={<SendIcon backgroundColor="grey" />}
                                  onClick={() => {
                                    handleShowOpinion(entry, index)
                                  }}
                                  style={{
                                    border: "none",
                                    marginLeft: '12px',
                                    borderRadius: 10,
                                    fontWeight: 'bold',
                                    color: "white",
                                    backgroundColor: "#5B76D7",
                                    display: "flex",
                                    alignItems: "center"
                                  }}>
                                  {isDisabled ? "Disabled" : "READ, RATE"}
                                </Button>
                              </TableCell>
                              {showLoginAlert && (
                                <Snackbar
                                  open
                                  autoHideDuration={3000}
                                  onClose={handleClose}
                                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                  sx={{
                                    marginTop: "100px"
                                  }}
                                >
                                  <Alert onClose={handleClose} severity="error">
                                    <span style={{ fontSize: 16, fontStyle: 'italic' }}>Login first...</span>
                                  </Alert>
                                </Snackbar>
                              )}
                            </TableRow>
                            {/* )} */}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                    {/* <TablePagination
                  component="div"
                  count={sortedResults.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>

          </Box>

        </ Card>
      </>
    )
  }

  function getCategory() {
    return (
      <Grid item xs={12}>
        <Stack direction='row' paddingLeft='6px' paddingRight='6px'>
          <>
            <Card
              elevation={10}
              style={{
                align: "left",
                justifyContent: "flex-start",
                marginTop: "1em",
                marginBottom: "1em",
                marginLeft: "12px",
                paddingLeft: '12px',
                paddingRight: '0px',
                width: "130px",
              }}
            >
              <Stack
                spacing={2}
                direction="column"
                marginLeft={1.1}
                marginTop={1}
                marginBottom={1}
              >
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick(1)}
                  style={{
                    border: "none",
                    width: "100px",
                    height: "90px",
                    backgroundColor:
                      selectedButton === 1 ? buttonSelectedColor : button2Color,
                    color: selectedButton === 1 ? "white" : "grey",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    textTransform: "none",
                  }}
                >
                  CURRENT AFFAIRS
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick(2)}
                  style={{
                    border: "none",
                    width: "100px",
                    height: "90px",
                    fontFamily: "'Open Sans', sans-serif",
                    backgroundColor:
                      selectedButton === 2 ? buttonSelectedColor : button2Color,
                    color: selectedButton === 2 ? "white" : "grey",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    // boxShadow: "80%",
                    textTransform: "none"
                  }}
                >
                  ECONOMY, FINANCE
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick(3)}
                  style={{
                    border: "none",
                    width: "100px",
                    height: "90px",
                    fontFamily: "'Open Sans', sans-serif",
                    backgroundColor:
                      selectedButton === 3 ? buttonSelectedColor : button2Color,
                    color: selectedButton === 3 ? "white" : "grey",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    // boxShadow: "80%",
                    textTransform: "none"
                  }}
                >NUTRITION, LIFESTYLE
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick(4)}
                  style={{
                    border: "none",
                    width: "100px",
                    height: "90px",
                    fontFamily: "'Open Sans', sans-serif",
                    backgroundColor:
                      selectedButton === 4 ? buttonSelectedColor : button2Color,
                    color: selectedButton === 4 ? "white" : "grey",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    // boxShadow: "80%",
                    textTransform: "none"
                  }}
                >MEDIA
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick(5)}
                  style={{
                    border: "none",
                    width: "100px",
                    height: "90px",
                    fontFamily: "'Open Sans', sans-serif",
                    backgroundColor:
                      selectedButton === 5 ? buttonSelectedColor : button2Color,
                    color: selectedButton === 5 ? "white" : "grey",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    // boxShadow: "80%",
                    textTransform: "none"
                  }}
                >TECHNOLOGY
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick(6)}
                  style={{
                    border: "none",
                    width: "100px",
                    height: "90px",
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 'bold',
                    backgroundColor:
                      selectedButton === 6 ? buttonSelectedColor : button2Color,
                    color: selectedButton === 6 ? "white" : "grey",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    // boxShadow: "80%",
                    textTransform: "none"
                  }}
                >Quirky, Eccentric
                </Button>
              </Stack>
            </ Card>
          </>
        </ Stack>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Stack direction="row">
        {getCategory()}
        {displayState === "showOpList" && renderOpinionList()}
        {displayState === "showOp" && renderOpinion()}
        {displayState === "showAndRateOp" ? (
          <>
            {renderOpinion()}
            {getRatings()}
          </>
        ) : null}
        <OpinionUpload userId={userId} onLogin={onLogin} onLogout={onLogout} />
        {/* {selectedButton === 1 && <ProCorpCX userId={userId} onLogin={onLogin} onLogout={onLogout} />}
      {selectedButton === 2 && <ProCorpPrivate userId={userId} onLogin={onLogin} onLogout={onLogout} />}
      {selectedButton === 3 && <ProEducation userId={userId} onLogin={onLogin} onLogout={onLogout} />} */}
      </Stack>
    </React.Fragment>
  );
}

export default OpinionsOptions;
